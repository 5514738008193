nav {
	position: fixed;
	top: 0;
	left: 0;
	z-index: 10;

	display: flex;
	justify-content: space-evenly;
	align-items: center;
	
	width: 100vw;
	height: 42px;
	list-style: none;
	padding: 0 0 4px;
	margin: 0 auto;
	background-color: var(--color-ends);
}
nav a {
	color: #609E7D
}
nav a.current {
	color: #80D1A7;
}
nav a:hover {
	color: rgb(161, 231, 195);
	text-decoration: underline;
}