:root {
	--color-dark: rgb(44, 34, 49);
	--color-ends: rgb(25, 25, 25);
}

.App {
  display: flex;
	flex-direction: column;
	font-size: 17px;
	text-align: center;
	background-color: var(--color-ends);
	color: var(--color-dark);
}

.serif-type, h1, section h2 {
	font-family: 'Roboto Slab', serif;
}
.mono-type, nav, footer, section a {
	font-family: 'Roboto Mono', monospace;
}
.narrow-type, h2.hero h3 {
	font-family: 'Roboto Condensed', sans-serif;
}



a {
	cursor: pointer;
	text-decoration: none;
	color: #D68B00
}

.link-underline {
	text-decoration: underline;
}



h2 {
	font-size: 28px;
}
h3 {
	letter-spacing: 2px;
	margin: 24px 0 0;
}

section {
	margin: 0 auto;
	padding: 43px 3% 6%;
	line-height: 1.9;
}
section#about {
	background-color: #CCC5B8;
	padding-top: 1%;
}
section#projects {
	background-color: #BDB7AE;
}
section#contact {
	background-color: var(--color-dark);
}



.recenter {
	text-align: center;
}

@media only screen and (min-width: 728px) {
	h1 {
		font-size: 42px;
	}
	h2 {
		font-size: 36px;
	}
	h3 {
		font-size: 30px;
	}

	p {
		max-width: 74%;
	}
	#projects p {
		max-width: 75%;
	}

	.contact-shift {
		padding-left: 35%;
	}
}