#projects h2 {
	text-decoration: underline;
}

h4 {
	margin-top: 0;
	font-size: 18px;
}

.project-item p {
	margin-left: auto;
	margin-right: auto;
}



.screenshot {
	width: 95%;
	max-width: 480px;
	box-shadow: 5px 5px gray;
	margin: 1% auto;
}



.project-links {
	margin: 36px auto;
	display: flex;
	flex-direction: column;
}

.project-links .project-btn {
	display: block;
	border: 2px solid var(--color-dark);
	border-radius: 6px;
	margin: 7px auto;
	padding: 4px 0 8px;
	height: 42px;
	width: 300px;
}

.live-link {
	background-color: var(--color-dark);
}
.live-link:hover {
	background-color: rgb(75, 46, 92);
	border-color: rgb(69, 57, 75);
}

.github-link {
	color: #261D2B;
}
.github-link:hover {
	background-color: rgb(141, 138, 143);
}