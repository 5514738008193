header {
	margin: 43px auto 0;
	padding: 0 1%;
}

h1 {
	margin: 12px 0 25px;
	letter-spacing: 1px;
	font-size: 36px;
	color: rgb(235, 235, 235);
	text-shadow: 1px 1px gray;
}

h2.hero {
	margin-top: 3%;
	letter-spacing: 2px;
	color: rgb(220, 220, 220);
}